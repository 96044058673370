import React, { useEffect, useRef } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video && !video.paused) return;
      video.play().catch((error) => console.log(error));
    };

    const pauseVideo = () => {
      if (video && !video.paused) {
        video.pause();
      }
    };

    playVideo();

    document.addEventListener('visibilitychange', () => {
      document.hidden ? pauseVideo() : playVideo();
    });

    return () => {
      document.removeEventListener('visibilitychange', () => {});
    };
  }, []);

  return (
    <div className='hero-container'>
      <video ref={videoRef} src='/videos/video-1.mp4' autoPlay loop muted playsInline />
      <h1>Believe and Confess</h1>
      <p>Start Reading Now</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          href='/sign-up'
        >
          GET STARTED
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          href='https://www.youtube.com/watch?v=bGeNnbkH2v4'
        >
          WATCH A VIDEO <i className='far fa-play-circle' />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
